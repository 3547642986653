import React from "react";
import "./App.css";
import Sharkle from "./views/Sharkle/Sharkle";

function App() {
	return (
		<div className="App">
			<Sharkle />
		</div>
	);
}

export default App;
